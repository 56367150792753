
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import {
  BEND_GANTT_PRODUCTION_MODES,
  BendIntradayTableData,
  BendGanttState,
  BendGroupedGanttTableData,
  FlowSpeedGanttState,
  BEND_GANTT_FLOW_SPEED,
  BendGanttSpeedFlowStates,
} from '@/models/Charts/chartsData';
import TableWithActions2 from '@/components/tableWithActions/TableWithActions2.vue';
import TableActionButton from '@/components/tableWithActions/TableActionButton.vue';
import CellWithSubtitle from '@/components/tableWithActions/CellWithSubtitle.vue';
import { isEmpty } from '@/utils/misc';
import { Device } from '@/models/device';
import { devicesService } from '@/services/devices.service';
import WidgetDefinition from '@/models/Charts/widgetDefinition';
import { getBendFlowSpeedColor, getBendStateColor } from '@/utils/color';
import GanttChartRow from '../GanttChartRow.vue';
import GanttTimelineRow from '../GanttTimelineRow.vue';
import {
  filterDataByZoomAndDevice,
  filterDeviceDataByStates,
  getDefaultZoomValues,
  getLastFlowSpeed,
  getLastState,
  getTotalLengthOfState,
} from '@/utils/gantt';
import { SheetPartsGanttGenerator } from '@/models/Charts/gantt/sheetPartsGanttGenerator';
import { ProductionModeGanttGenerator } from '@/models/Charts/gantt/productionModeGanttGenerator';
import { FlowSpeedGanttGenerator } from '@/models/Charts/gantt/flowSpeedGanttGenerator';
import { decimalFormatter } from '@/utils/number';
import BendGanttChartFilters from './BendGanttChartFilters.vue';

@Component({
  components: {
    CellWithSubtitle,
    BendGanttChartFilters,
    GanttChartRow,
    TableWithActions2,
    TableActionButton,
    GanttTimelineRow,
  },
  computed: {
    BendGanttSpeedFlowStates: () => BendGanttSpeedFlowStates,
  },
  methods: {
    decimalFormatter,
    getBendStateColor,
    isEmpty,
    getTotalLengthOfState,
    getLastState,
    getLastFlowSpeed,
    filterDeviceDataByStates,
    getBendFlowSpeedColor,
  },
})
export default class BendGanttChartTable extends Vue {
  @Prop({ required: true })
  private widgetDefinition!: WidgetDefinition;

  @Prop({ required: true })
  private data!: BendIntradayTableData | null;

  @Prop({ required: true })
  private loading!: boolean;

  private states: BendGanttState[] = BEND_GANTT_PRODUCTION_MODES;
  private flowSpeedStates: FlowSpeedGanttState[] = BEND_GANTT_FLOW_SPEED;

  private defaultZoomValues: [Date, Date] = getDefaultZoomValues();
  private zoomValues: [Date, Date] = getDefaultZoomValues();

  private showGantt = true;

  private get productionModeGanttGenerator() {
    return new ProductionModeGanttGenerator();
  }

  private get sheetPartsGanttGenerator() {
    return new SheetPartsGanttGenerator();
  }

  private get flowSpeedGanttGenerator() {
    return new FlowSpeedGanttGenerator();
  }

  private get deviceIds(): string[] {
    return this.widgetDefinition.deviceIds;
  }

  private get dataGroupedByDevice(): BendGroupedGanttTableData[] {
    if (!this.data) return [];
    return this.deviceIds.map((deviceid) => ({
      deviceid,
      productionModeData: filterDataByZoomAndDevice(
        this.data!.productionModeData,
        this.zoomValues,
        deviceid,
      ),
      sheetPartsData: filterDataByZoomAndDevice(
        this.data!.sheetPartsData,
        this.zoomValues,
        deviceid,
      ),
      flowSpeedData: filterDataByZoomAndDevice(this.data!.flowSpeedData, this.zoomValues, deviceid),
    }));
  }

  private get devices(): Device[] {
    return devicesService.store.get().filter((x) => this.deviceIds.includes(x.deviceId));
  }

  private getDeviceNameByDeviceId(deviceid: string): string {
    return this.devices.find((x) => x.deviceId === deviceid)!.name;
  }

  private async refresh() {
    this.$emit('refresh');
  }

  private onFiltersChanged(
    states: BendGanttState[],
    zoomValues: [Date, Date],
    flowSpeedStates: FlowSpeedGanttState[],
    areApplying: boolean,
    next: (filterValues: Record<string, any>, areApplying: boolean) => void,
  ) {
    this.states = states;
    this.flowSpeedStates = flowSpeedStates;
    this.zoomValues = zoomValues;
    next({}, areApplying);
  }

  @Watch('data')
  private watchData() {
    this.defaultZoomValues = getDefaultZoomValues();
    this.zoomValues = this.defaultZoomValues;
  }
}
